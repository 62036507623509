<template>
  <v-switch
    v-model="value"
    :disabled="disabled"
    inset
    class="mt-0 mb-2 mb-sm-0 mr-n3 mr-sm-0 ml-4 ml-sm-0 no-input-details pa-0"
    :data-cy="`plugin-switcher-${plugin.id}`"
    @change="setPluginSelection"
  />
</template>

<script>

export default {
  props: {
    plugin: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: this.checked
    }
  },
  watch: {
    checked (val, oldVal) {
      if (val !== oldVal) {
        this.value = this.checked
      }
    }
  },
  methods: {
    setPluginSelection: function (checked) {
      this.$emit('plugin-change', { plugin: this.plugin, checked })
    }
  }
}
</script>
